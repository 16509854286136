jQuery(function($) {
  function gererScrollChiffres() {
    var chiffres = $('#chiffres');

    if (chiffres.length !== 0) {
      chiffres.find('.chiffre').addClass('inactif');

      var limite = chiffres.offset().top + chiffres.outerHeight() / 2;
      $(window)
        .scroll(function() {
          if ($(this).scrollTop() + $(window).height() > limite) {
            chiffres.find('.chiffre').each(function(index, element) {
              setTimeout(function() {
                $(element).addClass('scroll');
              }, 500 + index * 100);
            });
          }
        })
        .scroll();
    }
  }

  function gererLanguesConsignesSecurite() {
    var consignesSecurite = $('#consignes_securite');

    if (consignesSecurite.length !== 0) {
      var langues = consignesSecurite.children('#langues');

      if (langues.length !== 0) {
        langues.css('display', 'block');

        langues.children().click(function(e) {
          var langue = $(this);
          var consignes = consignesSecurite.children('div[data-langue="' + langue.data('langue') + '"]');

          if (consignes.length !== 0) {
            langue.siblings().removeClass('actif');
            langue.addClass('actif');

            consignes.siblings('div').hide();
            consignes.show();
          }

          e.preventDefault();
        });

        langues
          .children()
          .eq(0)
          .click();
      }
    }
  }

  function gererSlider() {
    $('#slider').slick({
      arrows: false,
      dots: true,
    });
  }

  function initialiser() {
    gererSlider();
    gererLanguesConsignesSecurite();
    gererScrollChiffres();
  }

  initialiser();
});

jQuery(function($) {
  function gererScrollEntete() {
    var entete = $('#en-tete');

    if (entete.length !== 0) {
      $(window)
        .scroll(function() {
          if ($(this).scrollTop() > 100) entete.addClass('scroll');
          else entete.removeClass('scroll');
        })
        .scroll();
    }
  }

  function initialiser() {
    gererScrollEntete();
  }

  initialiser();
});
